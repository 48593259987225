import { QuickReplace } from "static/js/app/utils/QuickReplace";
import * as Api from "static/js/app/api/endpoints";
import DOMPurify, { Config } from "dompurify";
import { FinanceSearchTerms, SearchCriteria, SortTerm, VehicleSearchResult } from "static/js/app/models/__index";
import { HugoModuleVehiclesSliderConfig } from "themes/module_recent_vehicles_slider/static/js/recentVehiclesHugoConfig";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import { Page } from "static/js/app/modules/page";

import { VehicleStatus, VehicleStatusFromString } from "static/js/app/models/__index";
export type RecentSliderType =   "recentlyadded"| "recentlysold"| "recentlydiscounted";
export default class RecentlyVehiclesSlider {

    public static init(sliderType: RecentSliderType) {
        
        const configEl = document.getElementById(`${sliderType}-config`);
        if (configEl == null)
        {
            console.log(`${sliderType} - config not found`);
            return;
        }
        const config = new HugoModuleVehiclesSliderConfig(JSON.parse(JSON.parse(configEl.innerHTML)));

        const template = document.getElementById(`${config.dom_template_element_id}`);
        const el = document.getElementById(config.dom_element_id);
        if (template == null || el == null || config == null)
        {
            return;
        }
        let recentVehiclesBehaviour = el.dataset.recentvehiclesbehaviour;
        if (recentVehiclesBehaviour == null || recentVehiclesBehaviour =="off")
        {
            return;
        }
        let searchTerms = [{ fieldName: 'has_images', targetValue: "true" }]; 
        let vehicleStatus:VehicleStatus = (recentVehiclesBehaviour.startsWith("any") ? "all" : recentVehiclesBehaviour.startsWith("new")? "new":  "used");         
        if (recentVehiclesBehaviour != null && recentVehiclesBehaviour != "any")
        {            
            searchTerms.push({ fieldName: 'vehicle_type', targetValue: recentVehiclesBehaviour.replace(/any|used|new|s|\s/ig, '')});
        }
         
        const sortCriteria = config?.sort_by?.map(key => new SortTerm(key, true));
        
        const searchCriteria = new SearchCriteria(searchTerms,null, sortCriteria, 1, config.number_of_items_to_display,config.availability,vehicleStatus,);

        Api.Vehicles.search(searchCriteria).then(function (searchResults: VehicleSearchResult) {
            if (searchResults.total > 0 && searchResults.results.some(r => r.main_image != null && r.main_image[config.image_size] != null)) {
                Page.ready(function () {
                    var RecentlyAddedVehiclesTemplate = document.getElementById(config.dom_template_element_id);

                    if (RecentlyAddedVehiclesTemplate) {

                        RecentlyVehiclesSlider.generateRecentlyAddedVehiclesHtml(searchResults, RecentlyAddedVehiclesTemplate, config);

                        // slick
                        let slickBody = $(`#${config.dom_element_id}`);
                
                       
                        slickBody.slick(JSON.parse(config.slick_settings));

                        // show section titles
                        var slickHead = slickBody.siblings("h2");
                        slickHead.removeClass("hide");

                        // show section titles
                        var slickHeadAlt = slickBody.siblings("div .section-title").find("h2").filter(":first");
                        slickHeadAlt.removeClass("hide");

                        // adjust height of items to all be the same as the tallest image
                        if(config.equal_height) {
                            RecentlyVehiclesSlider.equalHeightsRecentlyAddedVehicles();
                        }
                    }
                });
            }
        });
    }

    private static generateRecentlyAddedVehiclesHtml(searchResults: VehicleSearchResult, template: HTMLElement, config: HugoModuleVehiclesSliderConfig) {
        var items = searchResults.results;

        // Get the contents of the template
        var templateHtml = template.innerHTML;
        // Final HTML variable as empty string
        var listHtml = "";

        // regex creation is reasonably expensive, cache the regex built for repeated templates like this
        var cachedRegEx: RegExp = null;
        var regExCacher = function (regex: RegExp) {
            // return the cachced regex if no regex is supplied, or cache it and return it if it is supplied
            if (regex !== null) {
                cachedRegEx = regex;
            }
            return cachedRegEx;
        };

        // Loop through items, replace placeholder tags
        // with actual data, and generate final HTML
        // Loop through items, replace placeholder tags
        // with actual data, and generate final HTML
        for (var i = 0; i < items.length; i++) {
            if (items[i].main_image != null && items[i].main_image[config.image_size] !== null) {
                var price: string = (items[i].sold ==true ? "SOLD" 
                : (items[i].price > 0)
                    ? '£' + Number(items[i].price).toLocaleString(undefined, { maximumFractionDigits: 0 })
                    : "POA");
                

                var monthly_payment_amount: string = 
                    (items[i].monthly_payment > 0)
                        ? Number(items[i].monthly_payment).toLocaleString(undefined, { maximumFractionDigits: 0 })
                        : "";

                var monthly_payment = ((config.show_monthly_payment) && (items[i].monthly_payment > 0)) ? 
                    `<div class="l-node-used-vehicle--latest-vehicles__price">
                        <div class="field field-name-field-vehicle-price">Monthly From £${monthly_payment_amount}</div>
                    </div>`
                    : "";

                var vatExempt: string = (items[i].vat_exempt == true)
                    ? "<div class='field-name-field-vehicle-vat'>No VAT</div>"
                    : "";

                var vatExcluded: string = (items[i].vat_excluded == true)
                    ? "<div class='field-name-field-vehicle-vat'>Plus VAT</div>"
                    : "";

                // string replacements to perform
                var replacements = {
                    '%vehicle_id%': items[i].id,
                    '%vehicle_photo%': (items[i].main_image != null) ? items[i].main_image[config.image_size] : "/awaitingimage.jpg",
                    '%vehicle_type%': (items[i].vehicle_type && items[i].vehicle_type.length) ? items[i].vehicle_type.toLowerCase() : '',
                    '%vehicle_type_plural%': (items[i].vehicle_type && items[i].vehicle_type.length) ? items[i].vehicle_type.toLowerCase() + 's' : '',
                    '%vehicle_make%': items[i].manufacturer,
                    '%vehicle_make_display%': items[i].manufacturer_display,
                    '%vehicle_model%': items[i].model,
                    '%vehicle_model_display%': items[i].model_display,
                    '%vehicle_derivative%': StringFormatting.coerceNullishToBlank(items[i].derivative),
                    '%vehicle_derivative_display%': StringFormatting.coerceNullishToBlank(items[i].derivative_display),
                    '%vehicle_price%': price,
                    '%vehicle_monthly_payment%': monthly_payment,
                    '%vehicle_year%': StringFormatting.coerceNullishToBlank(items[i].year_built),
                    '%vehicle_fuel%': StringFormatting.coerceNullishToBlank(items[i].fuel_type_display),
                    '%vehicle_transmission%': StringFormatting.coerceNullishToBlank(items[i].transmission_display),
                    '%vehicle_mileage%': StringFormatting.coerceNullishToBlank(items[i].mileage),
                    '%vehicle_mpg%': StringFormatting.coerceNullishToBlank(items[i].mpg.toString()),
                    '%vehicle_vat_exempt%': vatExempt,
                    '%vehicle_vat_excluded%': vatExcluded,
                    "%vehicle_details_url%": items[i].urlPath
                };

                // do replacements (using regex caching)
                listHtml += QuickReplace.quickReplace(replacements, templateHtml, regExCacher);
            }
        }

        // Replace the HTML of #searchResults with final HTML
        document.getElementById(config.dom_element_id).innerHTML = DOMPurify.sanitize(listHtml);
    }

    private static equalHeightsRecentlyAddedVehicles() {

        var RecentlyAddedVehiclesContainer = $('.l-node-used-vehicle--RecentlyAdded-vehicles__main-content');

        // set a base value
        var maxHeight = -1;
        var maxOuterHeight = -1;

        // Work out the element with the greatest height
        RecentlyAddedVehiclesContainer.each(function () {
            maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
            maxOuterHeight = maxOuterHeight > $(this).outerHeight() ? maxOuterHeight : $(this).outerHeight();
        });

        // Set the height
        RecentlyAddedVehiclesContainer.each(function () {
            $(this).height(maxHeight);
            $(this).css('min-height', maxOuterHeight);
        });
      }

}
