import PopularMakes from "themes/module_popular_makes/static/js/popularMakes";
import PageSlideshow from "static/js/app/modules/pageSlideshow";
import MenuTop from "static/js/app/modules/menuTop";
import HeaderSearch from "static/js/app/modules/headerSearch";
import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import * as Api from "static/js/app/api/endpoints";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import { PageContentImageSlider } from "themes/module_page_content_image_slider/static/js/pageContentImageSlider";
import { SearchBox } from "themes/module_searchbox/static/js/searchbox";
import MainMenu from "static/js/app/modules/mainMenu";
import { PartExchange } from "themes/module_part_exchange/static/js/partExchange";
import LatestVehicles from "themes/module_latest_vehicles_slider/static/js/latestVehicles";
import { Map, Pin } from "themes/module_map/static/js/map";
import Dropzone from "dropzone";
import { Vehicle } from "static/js/app/models/vehicle";
import { StickyElement } from "static/js/app/modules/stickyElement";
import { VehiclePageConfig } from "static/js/app/hugoSettings/pageConfigs/vehiclePageConfig";
import { FinanceSearchTerms, VehicleStatusFromString } from "static/js/app/models/__index";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { Browser, DivOverlay } from "leaflet";

export default class Common {
    public static Init(siteConfig: SiteConfig, pageConfig: PageConfig) {
        Common.applyPlaceholderImagesOnError();

        //Disable dropzone from trying to find an element on the page automatically
        Dropzone.autoDiscover = false;
        Api._init(siteConfig.snapiBaseUrl);

        if(siteConfig.latestVehiclesConfig.main.include_on_homepage || pageConfig.jsPageId != "home") {
            // init latest vehicles sidebar if not on homepage
            const financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
            LatestVehicles.init(siteConfig.latestVehiclesConfig.main, siteConfig.latestVehiclesSearchVehicleType, (siteConfig.latestVehiclesShowMonthlyPriceOn.indexOf("main") != -1),financeSearchTerms);
        }

        MainMenu.init("#main-menu", siteConfig.mainMenuItems);

        Map.initializeBranchesMap(siteConfig.map, "mapFooter", siteConfig.dealerBranches);

        Page.pushUrlVariablesToQueryString();

        Page.ready(function () {
          if (siteConfig.stickyNav) {
            var stickyElement = $('.l-header__nav-inner');
  					var stickyWatchElement = $('.l-header__nav');
  					new StickyElement(stickyElement, stickyWatchElement);
          }
          Map.initializeDataBackedMaps(".datamap");
          Common.openingHourHighlight();
        });
    }

    public static applyPlaceholderImagesOnError() {
        document.addEventListener('error', (e) => {
            if((e.target as HTMLElement).tagName == "IMG") {
                const img = e.target as HTMLImageElement;
                
                if (Browser.ie && img.src.indexOf(".webp") > 0)
                {
                    console.log("IE Detected, webp fallback to jpg")
                    img.src =  img.src.replace(".webp",".jpg");
                    return;
                }
                
                var imagePlaceholderPath = (( img?.src !=null && img.src != undefined && img.dataset?.placeholder !=null && img.dataset?.placeholder != "" && img.src !== img?.dataset?.placeholder)
                 ? img.dataset.placeholder : '/basemedia/placeholder.jpg');     
                                      
                    img.src = imagePlaceholderPath;
                    img.classList.add("awaiting-image");
                
            }
            
          }, true);
    }

    public static debounceFormSubmitButtons() {
        const allForms = $("form");

        if (allForms.length > 0) {
            allForms.each((idx, frmEl) => {
                $(frmEl).on("submit", (evt) => {
                    const frm = $(evt.target);
                    
                    const btn = frm.find("input:submit").first() as JQuery<HTMLButtonElement>;

                    btn.attr("disabled", "true");

                    setTimeout(() => { btn.removeAttr("disabled"); }, 4000);

                    return true;
                });
            });
        }
    }

    public static updateSavedVehiclesCount() {
        const savedVehiclesEl = $('#compare-count');
        const savedVehiclesCount = VehicleComparison.getVehiclesCount();
        savedVehiclesEl.each((idx, el) => {
            el.textContent = `${savedVehiclesCount}`;
        });
    }

    public static PageReady(siteConfig: SiteConfig, pageConfig: PageConfig) {

        const initialSelectedVehicleType = pageConfig.vehicleType || siteConfig.vehicleType;
        const initialSelectedVehicleStatus = (pageConfig.vehicleStatus ?? siteConfig.searchConfig.default_vehicle_status);       
        const isSearchPage = pageConfig.jsPageId == "vehicles";
        const isFinanceSearch = (siteConfig.financeConfig && siteConfig.financeConfig.provider != "none");
        
        // initialize search box
        const vehicleId = Page.queryString['id'];
        
        const initSearchBox = (vehicle?: Vehicle) => {
            const searchboxEl = $('#searchBox');
            if (searchboxEl != null && searchboxEl.length != 0 )
            {
                new SearchBox(siteConfig.searchConfig, siteConfig.dealerBranches,VehicleStatusFromString(initialSelectedVehicleStatus), initialSelectedVehicleType, isSearchPage, isFinanceSearch,
                vehicle?.manufacturer, vehicle?.model);
            }
        }
        if(pageConfig.jsPageId == "vehicle") {
            const vehiclePageConfig = pageConfig as VehiclePageConfig;
            
            initSearchBox(vehiclePageConfig.vehicle);
        } else {
            initSearchBox();
        }

        Page.ready(function () {
            PageSlideshow.init(".slick--page-slider");

            PageContentImageSlider.init(siteConfig.pageContentImageSliderConfig);

            MenuTop.init(siteConfig.menuTopConfig);
            HeaderSearch.init();

            var vehicleTypeForMakes = (pageConfig.vehicleType != null && pageConfig.vehicleType != "all")
                ? pageConfig.vehicleType.toLowerCase()
                : siteConfig.vehicleType;

            if(siteConfig.popularMakesModule != null) {
                PopularMakes.generatePopularMakesListItems(".footer .popular-makes", vehicleTypeForMakes, Page.getSearchUrl, 10);

                PopularMakes.generatePopularMakesListItems(".all-makes .popular-makes", vehicleTypeForMakes, Page.getSearchUrl);
            }

            Common.updateSavedVehiclesCount();

            //initialize px form
            if (pageConfig.jsPageId !== "vehicle")
            {
                new PartExchange("#part-exchange-form", null, pageConfig.form, siteConfig.service_quotation_tool);
            }

            Common.debounceFormSubmitButtons();

            if(pageConfig.url == "/error/") {
                Breadcrumbs.adjustBreadcrumbsForErrorPage(document.location.pathname);
            }
        });
    }

    public static openingHourHighlight() {
        var d = new Date().getDay();
        var openingDays = document.getElementsByClassName("day-"+d);
        for (var i = 0; i < openingDays.length; i++) {
            openingDays[i].classList.add('opening-day'); 
         }
    }
}
