import { VehicleDetailsPageConfig } from "static/js/app/pages/vehicle";
import { MenuTopConfig } from "static/js/app/modules/menuTop";
import { SearchConfig } from "static/js/app/modules/search";
import { HugoSiteConfig, ServiceQuotationToolConfig } from "static/js/app/hugoSettings/hugoSiteConfig";
import { HugoModuleLatestVehiclesSliderConfig } from "themes/module_latest_vehicles_slider/static/js/latestVehiclesSliderHugoConfig";
import { ModuleSimilarVehiclesConfig } from "themes/module_similar_vehicles_slider/static/js/similarVehiclesConfig";
import { PageContentImageSliderConfig } from "themes/module_page_content_image_slider/static/js/pageContentImageSlider";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import { MenuItem } from "static/js/app/modules/menuItem";
import { PopularMakesModuleConfig } from "themes/module_popular_makes/static/js/popularMakesModuleConfig";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { ModuleVehicleBranchDetailsConfig } from "themes/module_vehicle_branch_details/static/js/moduleVehicleBranchDetailsConfig";
import { HugoModuleMapConfig } from "themes/module_map/static/js/mapConfig";

export class SiteConfig {
    constructor(
        public snapiBaseUrl: string = "",
        public vehicleType: string = "",
        public seoTown: string = "",
        public seoCounty: string = "",
        public siteTitle: string = "",
        public latestVehiclesSearchVehicleType: string = "any",
        public latestVehiclesShowMonthlyPriceOn: string[] = [], // should only be made available to elites
        public latestVehiclesConfig: HugoModuleLatestVehiclesSliderConfig = new HugoModuleLatestVehiclesSliderConfig(),
        public vehicleDetailsPageSettings: VehicleDetailsPageConfig = new VehicleDetailsPageConfig(),
        public pageContentImageSliderConfig: PageContentImageSliderConfig = new PageContentImageSliderConfig(),
        public menuTopConfig: MenuTopConfig = new MenuTopConfig(),
        public mainMenuItems: MenuItem[] = [],
        public searchConfig: SearchConfig = new SearchConfig(),
        public financeConfig: FinanceConfig = new FinanceConfig(),
        public popularMakesModule: PopularMakesModuleConfig = new PopularMakesModuleConfig(),
        public dealerBranches: DealerBranchPublicInfo[] = [],
        public moduleVehicleBranchDetails: ModuleVehicleBranchDetailsConfig = new ModuleVehicleBranchDetailsConfig(),
        public similarVehiclesConfig: ModuleSimilarVehiclesConfig = null,
        public stickyNav: boolean = false,
        public map: HugoModuleMapConfig = new HugoModuleMapConfig(),
        public service_quotation_tool: ServiceQuotationToolConfig = new ServiceQuotationToolConfig()
    ) {}

    public static FromHugoSiteConfig(
        hugoSiteConfig: HugoSiteConfig,
        dealerBranches: DealerBranchPublicInfo[]
    ): SiteConfig {
        if(hugoSiteConfig == null) {
            // use defaults
            return new SiteConfig();
        }

        return new SiteConfig(
            hugoSiteConfig.snapi_baseurl,
            hugoSiteConfig.vehicle_type,
            hugoSiteConfig.seo.seo_town,
            hugoSiteConfig.seo.seo_county,
            hugoSiteConfig.dealer_name,
            hugoSiteConfig.latest_panel_behaviour,
            hugoSiteConfig.latest_panel_behaviour_show_monthly_price_on.split(",").map(s=>s.replace(" ", "")),
            hugoSiteConfig.module_latest_vehicles_slider,
            VehicleDetailsPageConfig.FromHugoVehicleDetailsPageConfig(hugoSiteConfig.vehicle_details_page),
            PageContentImageSliderConfig.FromHugoPageContentImageSliderConfig(hugoSiteConfig.module_page_content_image_slider),
            MenuTopConfig.FromHugoMenuTopConfig(hugoSiteConfig.menutop),
            (hugoSiteConfig.mainmenu != null) ? hugoSiteConfig.mainmenu.map(MenuItem.FromHugoMenuItem) : [],
            SearchConfig.FromHugoSearchConfig(hugoSiteConfig.search),
            FinanceConfig.FromHugoFinanceConfig(hugoSiteConfig.finance),
            (hugoSiteConfig.module_popular_makes != null) ? PopularMakesModuleConfig.FromHugoConfig(hugoSiteConfig.module_popular_makes) : null,
            dealerBranches,
            (hugoSiteConfig.module_vehicle_branch_details != null) ? ModuleVehicleBranchDetailsConfig.FromHugoConfig(hugoSiteConfig.module_vehicle_branch_details) : null,
            (hugoSiteConfig.module_similar_vehicles_slider != null) ? ModuleSimilarVehiclesConfig.FromHugoModuleSimilarVehiclesConfig(hugoSiteConfig.module_similar_vehicles_slider) : null,
            hugoSiteConfig.stickynav,
            hugoSiteConfig.map,
            hugoSiteConfig.service_quotation_tool,
        );
    }
}
