export class VehicleSearch {
	constructor(
		public rootUrl: string,
		public vehicleType: string = null,
		public fuelType: string = null,
		public make: string = null,
		public model: string = null,
		public bodyType: string = null,
		public gearboxType: string = null,
		public gearbox: string = null,
		public colour: string = null,
		
		public berth: string = null,
		public doors: string = null,
		//public engine: string = null, // aka fuel type
		public minEngineSize: string = null,
		public maxEngineSize: string = null,
		public insuranceGroup: string = null,
		public keywords: string = null,
		public minMpg: string = null,
		public maxMpg: string = null,

		public minLength: string = null,
		public maxLength: string = null,

		public minUnladenWeight: string = null,
		public maxUnladenWeight: string = null,

		public year: string = null,
		public vehicles: string = null,
		public branch: string = null,

		public minPrice: string = null,
		public maxPrice: string = null,
		
		public sortBy: string = null,
		public sortOrder: string = null,
		public minMonthlyPrice: string = null,
		public maxMonthlyPrice: string = null
	) { }
}