import { QuickReplace } from "static/js/app/utils/QuickReplace";
import { Page } from "static/js/app/modules/page";
import { SearchPager } from "static/js/app/modules/searchPager";
import "slick-carousel";
import { VehicleComparison } from "static/js/app/modules/vehicleComparison";
import Common from "static/js/app/pages/common";
import * as Api from "static/js/app/api/endpoints";
import VehicleStringFormatting from "static/js/app/utils/stringFormatting/vehicleStringFormatting";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
import DOMPurify from "dompurify";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import VehicleFinanceQuotes from "themes/module_finance_plugin/static/js/vehicle_finance_quotes";
import { FinanceConfig } from "themes/module_finance_plugin/static/js/financeConfig";
import VehicleFinanceChecker from "themes/module_finance_plugin/static/js/vehice_finance_checker";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import {buildVehicleImageThumbnails} from "static/js/app/templateHelpers/vehicleThumbnailTemplate";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import ListingsHelpers from "static/js/app/templateHelpers/ListingsHelpers";
import { finance_plugin } from "themes/module_finance_plugin/static/js/finance_plugin";

export default class StaticSearchPage {
    private static searchUrlGenerator: (search: Models.VehicleSearch) => string;
    private static crumbs: Models.BreadcrumbEntry[];
    private static sortTerms: [{ fieldName: string, isDescending: boolean }];

    private static pageNumber: number;
    private static itemsPerPage: number;
    private static sortBy: string;
    private static order: string;
    private static defaultVehicleType: string;
    private static defaultVehicleTypeDisplay: string;
    private static financeSearchTerms: Models.FinanceSearchTerms;

    public static async init(siteConfig: SiteConfig, pageConfig:PageConfig, vehicleStatus: Models.VehicleStatus = "used", availability: Models.Availability = "available" ) {
       
        StaticSearchPage.defaultVehicleTypeDisplay = siteConfig.vehicleType + "s";
        StaticSearchPage.defaultVehicleType = siteConfig.vehicleType.toLowerCase();
        StaticSearchPage.searchUrlGenerator = (search: Models.VehicleSearch) => Page.getSearchUrl(search, true);
        const searchTerms = ListingsHelpers.getSearchTerms();
        let relativeRootSearchUrl = (availability == "sold") ? "/sold-gallery/" : (vehicleStatus ==  "new" ? "/new-vehicles/": "/used/");
        
       
        StaticSearchPage.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        StaticSearchPage.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);

        let sortTerm = ListingsHelpers.getSortOrder(siteConfig.searchConfig.sort_by, siteConfig.searchConfig.sort_order);
        StaticSearchPage.sortBy = sortTerm.sortBy;
        StaticSearchPage.order = sortTerm.sortOrder;
        StaticSearchPage.financeSearchTerms = VehicleFinanceQuotes.getFinanceSearchTerms(siteConfig.financeConfig);
        StaticSearchPage.sortTerms = ListingsHelpers.getSortTerms(StaticSearchPage.sortBy, StaticSearchPage.order);      
        StaticSearchPage.configureSearchResultsHtml(StaticSearchPage.financeSearchTerms);
        Page.ready(() => {           
            VehicleFinanceQuotes.setFinanceSearchTerms(StaticSearchPage.financeSearchTerms);
            ListingsHelpers.setSortByOptions(StaticSearchPage.sortBy, StaticSearchPage.itemsPerPage, StaticSearchPage.order);
            const priceFilter = searchTerms.filter(s=>s.fieldName == "price")[0];
            const monthlyPriceFilter = searchTerms.filter(s=>s.fieldName == "finance_quotes.monthly_payment")[0];
            ListingsHelpers.setHiddenFilterOptions(priceFilter.targetRangeMin, priceFilter.targetRangeMax, monthlyPriceFilter.targetRangeMin, monthlyPriceFilter.targetRangeMax);
            ListingsHelpers.configureSearchFilterEvents();
            const searchFiltersForm = $("#searchFilters:first");
            searchFiltersForm.on("submit",
                (evt) => {
                    let minPriceEl = searchFiltersForm[0].querySelector('input[name="minprice"]') as HTMLInputElement;
                    let maxPriceEl = searchFiltersForm[0].querySelector('input[name="maxprice"]') as HTMLInputElement;
                    let minmonthlyPriceEl = searchFiltersForm[0].querySelector('input[name="minmonthlypayment"]') as HTMLInputElement;
                    let maxmonthlyPriceEl = searchFiltersForm[0].querySelector('input[name="maxmonthlypayment"]') as HTMLInputElement;

                    if(minPriceEl.value == "") { searchFiltersForm[0].removeChild(minPriceEl); }
                    if(maxPriceEl.value == "") { searchFiltersForm[0].removeChild(maxPriceEl); }
                    if(minmonthlyPriceEl.value == "") { searchFiltersForm[0].removeChild(minmonthlyPriceEl); }
                    if(maxmonthlyPriceEl.value == "") { searchFiltersForm[0].removeChild(maxmonthlyPriceEl); }

                    return true;
                }
            ); 
            const totalRecords = parseInt($('.search-pager').find('.pager').data("results-count") ?? 0);
           
            const totalPages =  (totalRecords == 0 || StaticSearchPage.itemsPerPage ==0 ||  totalRecords <=StaticSearchPage.itemsPerPage ? 1 : Math.ceil(totalRecords / StaticSearchPage.itemsPerPage));
            
            ListingsHelpers.initializePager(     
                totalPages,       
                StaticSearchPage.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                Page.queryString["sort-by"],
                Page.queryString["order"],
                `${window.location.protocol}//${window.location.host}`,
                relativeRootSearchUrl
            );
        });
    }

    



    private static configureSearchResultsHtml(financeSearchTerms?: Models.FinanceSearchTerms) {       
        const sr = document.getElementById("searchResults");   
        // remove empty overview list items
            ListingsHelpers.removeEmptySpecListEls(sr.querySelectorAll(".specs-list"));

            //Remove cta's from reserved vehicles
            [].forEach.call(sr.querySelectorAll("[data-hidewhenreserved=true]"),(reservedCta: HTMLElement)=> {reservedCta.remove();});

            // Replace the HTML of #searchResults with final HTML
        //find finance_representative_example
        [].forEach.call(sr.querySelectorAll(".compare-button"),(el: HTMLElement) => {
            
            let btnEl = (el.classList.contains("compare-button")) ? el : $(el).parents(".compare-button:first")[0]
            const vehId = btnEl.getAttribute("data-vehId");
            if(vehId != null) {                       
                ListingsHelpers.toggleVehicleCompareButtonState(StaticSearchPage.checkIfSaved(vehId), btnEl);
            }}


        );
        
        $(".compare-button").on("click", (evt) => {
            evt.preventDefault();
            let btnEl = (evt.target.classList.contains("compare-button")) ? evt.target : $(evt.target).parents(".compare-button:first")[0]
            const vehId = btnEl.getAttribute("data-vehId");
            if(vehId != null) {
                const error = VehicleComparison.toggleVehicleCompare(vehId);
                if (error != null) {
                    console.log(error);
                }

                Common.updateSavedVehiclesCount();

                ListingsHelpers.toggleVehicleCompareButtonState(StaticSearchPage.checkIfSaved(vehId), btnEl);
            }
        });
        const financePriceFields = sr.querySelectorAll<HTMLElement>(".list-monthly-payment");
        if (financePriceFields.length > 0)
        {
            const financeCheckerLinks = sr.querySelectorAll<HTMLElement>(".ivendi-finance-checker");            
            finance_plugin.fetchMonthlyPrices(Array.from(financePriceFields),financeSearchTerms,(financeCheckerLinks.length > 0? Array.from(financeCheckerLinks): null));
        }
        
    }

    private static removeEmptySpecListEls(specListEls: NodeListOf<Element>) {
        if(specListEls != null) {

            [].forEach.call(specListEls,(specListEl: Element)=>
            {
            let blankEls: Element[] = [];

            [].forEach.call(specListEl.children, (specEl: Element) => {
                const valEls = specEl.getElementsByClassName("field-value");
                if(valEls.length) {
                    if(valEls[0].innerHTML == "" || valEls[0].innerHTML == "null") {
                        blankEls.push(specEl);
                    }
                }
            });
            [].forEach.call(blankEls, (el: HTMLElement) => { specListEl.removeChild(el); });
            });
        }
    }   

    private static checkIfSaved(vehicleID: string) {
        return VehicleComparison.isVehicleInComparisonList(vehicleID);
    }

    private static toggleVehicleCompareButtonState(isSaved: boolean, compareBtnEl: HTMLElement) {
        const isMobileView = compareBtnEl.classList.contains("mobile-only");

        let saveSectionExtraClasses = "saved-button success";
        if (isSaved) {
            $(compareBtnEl)
                .addClass(saveSectionExtraClasses)
                .children("i").addClass("fas").removeClass("far");

            if (!isMobileView) {
                $(compareBtnEl).children("span")[0].textContent = " Saved";
            }
        } else {
            $(compareBtnEl)
                .removeClass(saveSectionExtraClasses)
                .children("i").addClass("far").removeClass("fas");

            if (!isMobileView) {
                $(compareBtnEl).children("span")[0].textContent = " Save";
            }
        }
    }

}
