export class FinanceQuote {
    
    constructor(
        public term: number,
        
        public annual_mileage: number,
        
        public percentage_deposit: number,

        public monthly_payment: number,

        public vat_inclusive_payment: number,

        public vat_exclusive_payment: number,

        public is_vat_exclusive: boolean,

        public name: string,

        public dealer_terms_id: string,

        public quote_id: string,

        public total_deposit: number,
        public amount_of_credit: number,
        public total_amount_payable: number,
        public fixed_rate_of_interest: number,
        public apr: number,
        public otr_price: number,
        public total_amount_of_charges: number,
        public final_payment: number,
        public type: string,
        public excess_mileage_rate: number,
        public contract_length: number,
        public lender: string
    ) {}
}