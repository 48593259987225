import { Page } from "static/js/app/modules/page";
import "slick-carousel";
import * as Api from "static/js/app/api/endpoints";
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import * as Models from "static/js/app/models/__index";
import { Breadcrumbs } from "themes/module_breadcrumbs/static/js/breadcrumbs";
import { PageConfig } from "static/js/app/hugoSettings/PageConfig";
import ListingsHelpers from "static/js/app/templateHelpers/ListingsHelpers";

export default class SoldGallery {
    private static sortTerms: { fieldName: string, isDescending: boolean }[];

    private static pageNumber: number;
    private static itemsPerPage: number;


    public static async init(siteConfig: SiteConfig, pageConfig:PageConfig, onTemplatesLoaded: () => void = null, availability: Models.Availability = "sold", vehicleStatus: Models.VehicleStatus= "all") {
        
  
        SoldGallery.pageNumber = (parseInt(Page.queryString["page"]) || 1);
        SoldGallery.itemsPerPage = (parseInt(Page.queryString["items-per-page"]) || siteConfig.searchConfig.items_per_page);
        SoldGallery.sortTerms =SoldGallery.getSoldSortTerms();

        let searchCriteria = new Models.SearchCriteria(null, null, SoldGallery.sortTerms, SoldGallery.pageNumber, SoldGallery.itemsPerPage, availability,vehicleStatus);

        const searchResults = await Api.Vehicles.search(searchCriteria);

        Page.ready(() => {
            SoldGallery.updatePageMetaDescription(siteConfig.seoTown, siteConfig.seoCounty, siteConfig.siteTitle);
            ListingsHelpers.synchronizeSearchInputs;            

            let relativeRootSearchUrl = "/sold-gallery/";

            Breadcrumbs.setBreadcrumbs(Breadcrumbs.getGalleryBreadcrumbs(relativeRootSearchUrl, "Sold Gallery"));

            ListingsHelpers.generateSearchResultsHtml(searchResults, pageConfig, relativeRootSearchUrl,null, siteConfig.dealerBranches);

            if (onTemplatesLoaded != null) {
                onTemplatesLoaded();
            }
            
            ListingsHelpers.initializeDefaultPager(
                searchResults.totalPages,
                SoldGallery.pageNumber,
                (Page.queryString["items-per-page"] != null ? parseInt(Page.queryString["items-per-page"]): null),
                `${window.location.protocol}//${window.location.host}`
            );
        });
    }
    private static getSoldSortTerms(): { fieldName: string, isDescending: boolean }[] {
        return [{ fieldName: 'sold_date', isDescending: true }];
    }
    private static getPageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string): string {

        return `Check out our previously sold vehicles at ${siteTitle} in ${seoTown}, ${seoCounty}`;
    }

    private static updatePageMetaDescription(seoTown: string, seoCounty: string, siteTitle: string) {
        $('meta[name="description"]').attr('content', SoldGallery.getPageMetaDescription(seoTown, seoCounty, siteTitle));
    }

    

 
  
}
