import * as Models from "static/js/app/models/__index";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting";
export class ivendi_finance_calculator
{
      private static readonly persistFinanceOptions:boolean = false;
    
    public static init(ivendiUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string = "financePluginContent" )
    {
        this.initIvendiFinanceScript(ivendiUsername, config, vehicleSingle,pluginContentDivId,this.GetIvendiPluginPath)
    }

    public static initFinanceChecker(ivendiUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string = "ivendi-finance-checker-placeholder" )
    {
        this.initIvendiFinanceScript(ivendiUsername, config, vehicleSingle,pluginContentDivId,this.GetIvendiFinanceCheckPath)
    }
    public static initReserveButton(ivendiUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string = "ivendi-reserve-button-placeholder" )
    {
        this.initIvendiFinanceScript(ivendiUsername, config, vehicleSingle,pluginContentDivId,this.GetIvendiReservePath)
    }

    private static initIvendiFinanceScript(ivendiUsername: string, config: Models.FinanceSearchTerms , vehicleSingle: Models.Vehicle, pluginContentDivId: string,getPluginPath: (request: ivendiRequest)=> string )
    {
        if (vehicleSingle.sold ==true)
        { 
            return;
        }
        let ivendiEl = document.querySelector(`#${pluginContentDivId}`);
        if (ivendiEl ==null)
        {
            return;
        }
        let ivendiRequest = this.MapIvendiRequent(ivendiUsername, vehicleSingle, config);
        
        let ivendiScriptTag = document.createElement("script");
        ivendiScriptTag.async = true;
        ivendiScriptTag.type = "text/javascript";     
        ivendiScriptTag.src = getPluginPath(ivendiRequest);
        ivendiEl.appendChild(ivendiScriptTag);
    }

    
    private static GetIvendiPluginPath(ivendiRequest:ivendiRequest): string
    {
        return `https://newvehicle.com/widgets/lib/finance-comparator/loader.js?${$.param(ivendiRequest)}`;        
    }

    private static GetIvendiFinanceCheckPath(ivendiRequest:ivendiRequest): string  
    {
        return `https://newvehicle.com/widgets/lib/finance-check-cta/loader.js?callToActionStyle=9&${$.param(ivendiRequest)}`;        
    }

    private static GetIvendiReservePath(ivendiRequest:ivendiRequest): string  
    {
        return `https://newvehicle.com/widgets/lib/reserve-vehicle-cta/loader.js?callToActionStyle=5&${$.param(ivendiRequest)}`;        
    }


    private static MapIvendiRequent(ivendiUsername: string, vehicle: Models.Vehicle, config: Models.FinanceSearchTerms): ivendiRequest
    {
        let vehicleClass = this.mapVehicleType(vehicle.vehicle_type);
        return new ivendiRequest(
            vehicle.ivendi_quotee_uid,
            ivendiUsername,
            "used",
            vehicle.vrm,
            vehicle.cap_id,
            StringFormatting.dateToString(vehicle.registration_date),
            vehicle.price,
            vehicle.vat_excluded ==false,
            vehicle.vat_qualifying,
            vehicle.mileage,            
            (vehicle.images.length > 0 ? `https://${window.location.host}/${vehicle.images[0].i850x638}` : ""),
            (config.percentage_deposit > 0 ? "percentageOfCashPrice" : "flatRate"),
            (config.percentage_deposit> 0 ? config.percentage_deposit : config.deposit_amount),
            config.annual_mileage,
            config.term,           
            this.persistFinanceOptions,
            vehicleClass);
                              
    }

    private static mapVehicleType(vehicleType: string): string {
        //allows values car, bike, lcv
        let cleanedType = StringFormatting.coerceNullishToBlank(vehicleType).toLowerCase();
        if (cleanedType === "car" || cleanedType === "bike")
        {
            return cleanedType;
        }
        if (cleanedType ==="commercial" || cleanedType === "van" || cleanedType ==="motorhome")
        {
            return "lcv"
        }
        return "";
    }


}

class ivendiRequest
    {
        public class: string;

        constructor(
        public quoteeUid: string,
        public username: string,
        public condition: string,
        public vrm: string,
        public capId: string,
        public registrationDate: string,
        public cashPrice: number,
        public vatIncluded: boolean,
        public vatQualifying: boolean,
        public currentOdometerReading: number,
        public vehicleImageUrl: string,
        public cashDepositType :string,
        public cashDeposit :number,
        public annualDistance: number,
        public term: number,
        public usePersistedOptions: boolean, 
        class_: string,
        public widgetId : string = "iv-finance-widget"
        
        ){ this.class = class_ }
    }

